import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState} from 'react';
import ProjectsList from "./components/ProjectsList";
import CategoryTree from "./components/CategoryTree";
import {GlobalProvider} from './components/GlobalProvider';
import LoginNew from './components/LoginNew';
import DisplayProjectId from "./components/DisplayProjectId";
import AssetTableNew from './components/AssetTableNew';
import AssetRelationShipsTable from './components/AssetRelationShipsTable';
import ViewerLeft from './components/ViewerLeft';
import ViewerRight from './components/ViewerRight';
import AccordionItem from './components/Accordion';
// import TokenButton from './components/TokenButton';
// import TestTable from './components/TestTable';
// import AirTable from './components/AirTable';

function App() {

    // TEST for airtable
    // const column = 'Client_Asset_ID';
    // const value = 'XMP_34';
    console.log('Current API URL:', process.env.REACT_APP_API_URL);
    console.log('Current NODE_ENV:', process.env.NODE_ENV);

    const [selectedLeftUrn, setSelectedLeftUrn] = useState('');
    const [selectedRightUrn, setSelectedRightUrn] = useState('');


    const handleSetSelectedLeftUrn = (urn) => {
        console.log('Selected handleSetSelectedLeftUrn:', urn);
        const encodedUrn = btoa(urn);
        setSelectedLeftUrn(encodedUrn);
    };

    const handleSetSelectedRightUrn = (urn) => {
        console.log('Selected handleSetSelectedRightUrn:', urn);
        const encodedUrn = btoa(urn);
        setSelectedRightUrn(encodedUrn);
    };


    return (

        <GlobalProvider>
            <div className="App  container-fluid">
                {/*<AirTable column={column} value={value} />*/}
                <AccordionItem title="Projects and Categories">
                    <div className="row fullRow">
                        <div>
                            <LoginNew/>
                        </div>

                    </div>
                    {/*<div className="row fullRow">*/}
                    {/*    <DisplayProjectId />*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col-md-3">
                            <div className="projectsList">
                                <ProjectsList/>
                            </div>
                            <div className="categoryTree">
                                <CategoryTree/>
                            </div>
                        </div>
                        <div className="col-md-12">
                        </div>
                    </div>
                </AccordionItem>
                <AccordionItem title="Viewers">
                    <div className="row fullRow">
                        <div className="col-md-6">
                            <div className="viewerLeft viewerContainer">
                                <ViewerLeft
                                    urn={selectedLeftUrn}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="viewerRight viewerContainer">
                                <ViewerRight
                                    urn={selectedRightUrn}
                                />
                            </div>
                        </div>
                    </div>

                </AccordionItem>
                <AccordionItem title="Issues">
                    <div className="row fullRow">
                        <div className="assetRelationShipsContainer">
                           Coming soon
                        </div>
                    </div>
                </AccordionItem>
                <AccordionItem title="RFIs">
                    <div className="row fullRow">
                        <div className="assetRelationShipsContainer">
                            Coming soon
                        </div>
                    </div>
                </AccordionItem>
                <AccordionItem title="Documents">
                    <div className="row fullRow">
                        <div className="assetRelationShipsContainer">
                            <AssetRelationShipsTable
                                setSelectedLeftUrn={handleSetSelectedLeftUrn}
                                setSelectedRightUrn={handleSetSelectedRightUrn}
                            />
                        </div>
                    </div>
                </AccordionItem>
                <AccordionItem title="Assets">
                    <div className="row fullRow">

                        <div className="assetContainer">
                            {<AssetTableNew/>}
                        </div>
                    </div>

                </AccordionItem>

            </div>
        </GlobalProvider>
    );
}

export default App;
