import React, { useEffect, useState } from "react";
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import TreeView, { flattenTree } from "react-accessible-treeview";
import styled from 'styled-components';
import "./treeView.css";
import { FetchCategories } from "../api/assetCategoriesService";
import { useGlobalProvider } from './GlobalProvider';
import { useAuth } from './AuthContext';

function buildNestedStructure(data) {
    const nodeMap = {};
    let root = null;




    data.forEach(item => {
        if (item.isRoot) {
            item.parentId = null;
        } else if (!item.hasOwnProperty('parentId')) {
            item.parentId = undefined;
        }
        nodeMap[item.id] = { ...item, children: [] };
    });

    data.forEach(item => {
        if (item.parentId === null) {
            root = nodeMap[item.id];
        } else if (nodeMap[item.parentId]) {
            nodeMap[item.parentId].children.push(nodeMap[item.id]);
        }
    });

    return root;
}

function CategoryTree() {
    const {selectedProjectId,selectedCategoryId, setSelectedCategoryId } = useGlobalProvider(); // Ensure correct usage of useGlobalProvider
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { getAccessToken } = useAuth();




    useEffect(() => {
        if (!selectedProjectId) {
            // console.log("No projectId available yet");
            return;
        } else {
            console.log("Project ID in CategoryTree:", selectedProjectId);
        }

        const fetchCategories = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const accessToken = await getAccessToken();
                // console.log('Token in category tree:', accessToken);
                const data = await FetchCategories({ selectedProjectId:selectedProjectId, accessToken:accessToken });
                if (!data || !data.results || !Array.isArray(data.results)) {
                    throw new Error('Invalid or empty data returned from API');
                }
                setCategories(data.results);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCategories();
    }, [selectedProjectId]);

    const structuredData = buildNestedStructure(categories);
    const data = structuredData ? flattenTree(structuredData) : [];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    // if (!data || data.length === 0) {
    //     return <div>No data available for category tree.</div>;
    // }
    // console.log('Data categories:', categories);
    if (categories.length === 0 || (categories.length === 1 && categories[0].name === "ROOT")) {
        return <div>No categories in project found</div>;
    }
    if (!selectedProjectId) {
        return
    }
    return (
        <div>
            <div className="checkbox">
                {/*{selectedProjectId && <p>Project ID in category tree view: {selectedProjectId}</p>}*/}
                <TreeView
                    data={data}
                    aria-label="Single select"
                    multiSelect={false}
                    propagateSelectUpwards
                    togglableSelect
                    nodeAction="check"
                    nodeRenderer={({ element, isBranch, isExpanded, isSelected, isHalfSelected, getNodeProps, level, handleSelect, handleExpand }) => (
                        <div
                            {...getNodeProps({ onClick: handleExpand })}
                            style={{ marginLeft: 40 * (level - 1) }}
                        >
                            {isBranch && <ArrowIcon isopen={isExpanded} />}
                            <CheckBoxIcon
                                className="checkbox-icon"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent event bubbling
                                    if (selectedCategoryId === element.id) {
                                        setSelectedCategoryId(1); // Deselect and set no selected category to root (1)
                                        console.log("Category deselected");
                                    } else {
                                        setSelectedCategoryId(element.id); // Set selected category ID on checkbox click
                                        console.log("Category ID:", element.id); // Log the new category ID
                                    }
                                    handleSelect(e); // Handle the select action (e.g., for UI updates)
                                }}
                                variant={isHalfSelected ? "some" : isSelected ? "all" : "none"}
                            />
                            <span className="name">{element.name}</span>
                        </div>
                    )}
                />
            </div>
        </div>
    );
}

const StyledArrow = styled(IoMdArrowDropright)`
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => isOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
  font-size: 24px; // You can adjust this value or make it dynamic as needed
    display: inline-block;
  //  padding-top:  10px;
`;

const ArrowIcon = ({ isOpen }) => (
    <StyledArrow isOpen={isOpen} />
);


// const ArrowIcon = ({ isOpen }) => (
//     <IoMdArrowDropright className={cx("arrow", { "arrow--closed": !isOpen, "arrow--open": isOpen })} />
// );

const StyledCheckSquare = styled(FaCheckSquare)`
    //border: 2px solid grey;
    //color: antiquewhite;
    //font-size: 14px;
`;

const StyledSquare = styled(FaSquare)`
    border: 1px solid grey;
    color: #CCCCCC;
    display: inline-block;
    //font-size: 14px;
`;

const StyledMinusSquare = styled(FaMinusSquare)`
    border: 2px solid grey;
    color: #CCCCCC;
    //font-size: 14px;
`;

const CheckBoxIcon = ({ variant, ...rest }) => {
    switch (variant) {
        case "all":
            return <StyledCheckSquare {...rest} />;
        case "none":
            return <StyledSquare {...rest} />;
        case "some":
            return <StyledMinusSquare {...rest} />;
        default:
            return null;
    }
};

export default CategoryTree;
