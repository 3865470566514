import React, {useState, useRef, useEffect} from 'react';
// import 'tabulator-tables/dist/css/tabulator.min.css';
import 'react-tabulator/lib/styles.css'; // default theme
import 'react-tabulator/css/tabulator_bootstrap5.min.css';
import {ReactTabulator} from 'react-tabulator';
import {CSVLink} from 'react-csv';
import {FetchCustomProperties} from "../api/assetCustomPropertiesService";
import {FetchCategories} from "../api/assetCategoriesService";
import {FetchStatuses} from "../api/assetStatusesService";
import {FetchAssets} from "../api/assetService";
import {PatchAssets} from "../api/assetUpdateService";
import {FetchCategoriesCustomAttributes} from "../api/assetCategoriesCustomAttributesService";
import {useGlobalProvider} from "./GlobalProvider";
import {useAuth} from './AuthContext';
// import AirTable from './AirTable';
import {AirtableService} from "../api/airtableService";
import {viewerLinkModes} from "../utilities/viewerUtils";

const TabulatorTable = () => {
    // const TabulatorTable = ({setSelectedTagId}) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [statusOptions, setStatusOptions] = useState({});
    const [customPropsMap, setCustomPropsMap] = useState({});
    const [categoryOptions, setCategoryOptions] = useState({});
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [assetUpdated, setAssetUpdated] = useState(false);
    const [changes, setChanges] = useState({});
    const [editableRowIndex, setEditableRowIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const tableRef = useRef(null);
    const {selectedProjectId, selectedCategoryId, selectedTagId, setSelectedTagId, setSelectedAssetId, setSelectedTagIdViewerLeft, setSelectedTagIdViewerRight} = useGlobalProvider();
    const prevRowRef = useRef(null); // Use a ref to store the previous row element
    const [customAttributes, setCustomAttributes] = useState([]);
    const customAttributesRef = useRef([]); // Use ref to keep latest customAttributes
    const [assetFetchOptions, setAssetFetchOptions] = useState([`?includeCustomAttributes=true&filter[categoryId]=${selectedCategoryId}&limit=200`]);
    const [reloadFlag, setReloadFlag] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const selectedOptionsRef = useRef(selectedOptions);
    const [actions, setActions] = useState({
        link2AirTable: false
    });


    useEffect(() => {
        console.log('Actions state updated asset table:', actions);
        // setReloadFlag(prevFlag => !prevFlag);
    }, [actions]);


// remove b. prefix from project id
    function removeBPrefix(str) {
        return str.replace(/^b\./, '');
    }

    // set clicked viewer objects id to tagid to search for
    useEffect(() => {
        console.log("selected tagId in asset table", selectedTagId);
        setSelectedTagId(selectedTagId);
        setAssetFetchOptions(`?filter[searchText]=${selectedTagId}&includeCustomAttributes=true&includeDeleted=false&limit=200`)
        // console.log("assetFetchOptions", assetFetchOptions);
    }, [selectedTagId]);
// for the initial load or if category changes
    useEffect(() => {
        setAssetFetchOptions(`?includeCustomAttributes=true&filter[categoryId]=${selectedCategoryId}&limit=200`);
    }, [selectedCategoryId]);




    const {getAccessToken} = useAuth();
    useEffect(() => {
        if (!selectedProjectId) {
            console.log("No projectId available yet");
            return;
        } else {
            console.log("No project ID in selected", selectedProjectId);
        }
        const fetchData = async () => {
            // debugger;

            try {
                const accessToken = await getAccessToken();
                const params = {
                    selectedProjectId: selectedProjectId,
                    selectedCategoryId: selectedCategoryId,
                    accessToken: accessToken
                };
                setIsLoading(true);
                console.log("asset fetch options", assetFetchOptions)

                const [customPropsResponse, categoriesResponse, statusesResponse, tableDataResponse] = await Promise.all([
                    FetchCustomProperties(params),
                    FetchCategories(params),
                    FetchStatuses(params),
                    FetchAssets(params,assetFetchOptions)
                ]);

                const fetchedCategoryOptions = categoriesResponse.results.reduce((acc, category) => {
                    acc[category.id] = category.name;
                    return acc;
                }, {});
                setCategoryOptions(fetchedCategoryOptions);

                const fetchedStatusOptions = statusesResponse.results.reduce((acc, status) => {
                    acc[status.id] = status.label;
                    return acc;
                }, {});
                setStatusOptions(fetchedStatusOptions);

                const newCustomPropsMap = customPropsResponse.results.reduce((acc, prop) => {
                    acc[prop.name] = prop.displayName;
                    return acc;
                }, {});
                setCustomPropsMap(newCustomPropsMap);

                const processedData = tableDataResponse && tableDataResponse.results ? tableDataResponse.results.map(item => ({
                    ...item,
                    ...item.customAttributes,
                    categoryId: fetchedCategoryOptions[item.categoryId],
                    statusId: fetchedStatusOptions[item.statusId] || item.statusId
                })) : [];

                setData(processedData);
                setIsLoading(false);

            } catch (err) {
                console.error('Error during fetch operations:', err);
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [reloadFlag]);


    const headerContextMenu = [
        {
            label: "Hide Column",
            action: function (e, column) {
                console.log("Hiding column");
                column.hide();
            }
        },
        {
            label: "Show All Columns",
            action: function (e, column) {
                console.log("Showing all columns");
                column.getTable().getColumns().forEach(function (col) {
                    col.show();
                });
            }
        },
    ];

    // Map the field names to their respective options objects
    const optionsMap = {
        categoryId: categoryOptions,
        statusId: statusOptions,
    };


    // Function to replace the values of specified keys in an object using multiple option objects
    const replaceKeys = (obj, keys, options) => {
        keys.forEach(key => {
            if (obj[key] && options[key]) {
                const optionObj = options[key];
                obj[key] = findKeyByValue(optionObj, obj[key]);
            }
        });
        return obj;
    };

    // Helper function to find the key by value in an object
    const findKeyByValue = (obj, value) => {
        return Object.keys(obj).find(key => obj[key] === value) || value;
    };
    useEffect(() => {
        customAttributesRef.current = customAttributes; // Keep the ref updated
    }, [customAttributes]);

    // for show left pf row the triangle button
    const expandRow = (cell, formatterParams) => {
        const button = document.createElement("button");
        button.innerHTML = "&#128898;"; // Right-pointing triangle character
        button.style.background = "none";
        button.style.border = "none";
        button.style.cursor = "pointer";
                button.setAttribute("data-expanded", "false");

        button.onclick = () => {
            const isExpanded = button.getAttribute("data-expanded") === "true";
            if (isExpanded) {
                button.innerHTML = "&#128898;"; // Right-pointing triangle
                button.setAttribute("data-expanded", "false");
            } else {
                button.innerHTML = "&#128899;"; // Down-pointing triangle
                button.setAttribute("data-expanded", "true");
            }
            toggleRow(cell.getRow());
        };

        return button;
    };





// TODO disable input for custom properties if there is no key in the asset so cant edit them
    const createColumns = () => {
        const hiddenKeys = ['id', 'isActive', 'updatedAt', 'createdAt', 'createdBy', 'updatedBy', 'companyId', 'customAttributes', 'version'];
        const renameMap = {
            categoryId: 'Category',
            statusId: 'Status',
            clientAssetId: 'Client Asset ID',
            description: 'Description'
        };

        const allKeys = new Set(data.reduce((keys, item) => {
            Object.keys(item).forEach(key => keys.add(key));
            return keys;
        }, new Set()));

        let columns = [];


        if (actions['link2AirTable']) {

            columns = [
                { title: '', field: 'expand', formatter: expandRow, width: 40, hozAlign: 'center' }
            ];
        } else {
            columns = [];
        }
        console.log("actions", actions);
        console.log(columns);


        // columns = [
        //     { title: '', field: 'expand', formatter: expandRow, width: 40, hozAlign: 'center' }
        // ];


        Array.from(allKeys).forEach(key => {
            const isHidden = hiddenKeys.includes(key);
            const title = renameMap[key] || customPropsMap[key] || key;

            let filterType, headerFilterParams;
            if (key === 'statusId' || key === 'categoryId') {
                filterType = "list";
                headerFilterParams = { values: Object.values(optionsMap[key]) };
            } else {
                filterType = "input";
                headerFilterParams = {};
            }

            let editorParams = {};
            let editor = false;

            if (editableRowIndex !== null) {
                if (key === 'statusId') {
                    editorParams = { values: Object.values(statusOptions) };
                    editor = 'list';
                } else if (key === 'categoryId') {
                    editorParams = { values: Object.values(categoryOptions) };
                    editor = 'list';
                } else {
                    editor = 'input';
                }
            }

            columns.push({
                title: title,
                field: key,
                visible: !isHidden,
                editor: editor,
                editorParams: editorParams,
                sorter: "string",
                headerContextMenu: headerContextMenu,
                formatter: "plaintext",
                headerFilter: filterType,
                headerFilterParams: headerFilterParams,
            });
        });

        return columns;
    };

    const columns = createColumns();

    // to show external data



    const rowMenu = [
        // TODO edit should be only possible when handleRowClick is finished
        {
            label: "Edit Asset",
            action: function (e, row) {
                setEditableRowIndex(row.getIndex());
                // setIsEditable(true);
                console.log('Row index set to editable:', row.getIndex());
            }
        },
        {
            label: "Open in ACC",
            action: (e, row) => {
                e.preventDefault();
                window.open(`https://acc.autodesk.eu/build/assets/projects/${removeBPrefix(selectedProjectId)}/assets?assetId=${row.getData().id}`);
            }
        },
        {
            label: "Find in Google Drive",
            action: (e, row) => {
                e.preventDefault();
                window.open(`https://drive.google.com/drive/search?q=${encodeURIComponent(row.getData().clientAssetId)}`);
            }
        },
        // TODO: have project id somewhere in the settings on server side
        {
            label: "Open in Cintoo",
            action: (e, row) => {
                e.preventDefault();
                window.open(`https://sandbox.cintoo.cloud/projects/bim/cHJvamVjdC02NA/threedview?q=type:tag%20label:${row.getData().clientAssetId}`);
            }
        },
        // TODO store empolis project id in the settings on server side
        {
            label: "Find in Empolis",
            action: (e, row) => {
                e.preventDefault();
                window.open(`https://esc-eu-central-1.empolisservices.com/service-express/portal/project1_p/search?useExpertQuery=0&text=${encodeURIComponent(row.getData().clientAssetId)}`);
            }
        }
    ];

    // Function to handle cell edit event
    const handleCellEdit = (cell) => {
        const rowId = cell.getRow().getData().id;
        const fieldName = cell.getField();
        const newValue = cell.getValue();



        // console.log('Cell value updated:', rowId, fieldName, newValue);
        setShowSaveButton(true);
        setChanges((prevChanges) => {
            const updatedRow = {
                ...(prevChanges[rowId] || {}),
                [fieldName]: newValue,
            };

            // Replace key values based on the options map
            const replacedRow = replaceKeys(updatedRow, Object.keys(updatedRow), optionsMap);

            return {
                ...prevChanges,
                [rowId]: replacedRow,
            };
        });
    };

    // Function to save changes
    const saveChanges = async () => {
        // console.log('Saving changes projectId:', selectedProjectId);
        const assets = Object.keys(changes).reduce((acc, id) => {
            acc[id] = changes[id];
            return acc;
        }, {});
        console.log('Changes array:', assets);
        try {
            const accessToken = await getAccessToken();
            const response = await PatchAssets({
                selectedProjectId: selectedProjectId,
                assets: assets,
                accessToken: accessToken
            });
            // console.log('Patch response:', response);
            // Print "Changes saved" only if the response is successful
            return 'Changes saved';
        } catch (error) {
            console.error('Error saving changes:', error);
            // Return null or an empty string to indicate failure
            return null;
        }
    };

    const handleSaveClick = () => {
        saveChanges().then(result => {
            if (result) {
                console.log(result);
            }
        });
        setShowSaveButton(false);
        setAssetUpdated(true);
        setEditableRowIndex(null);
    };


    // if (error) {
    //     return <div>Error loading data: {error}</div>;
    // }
    //
    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    // if (!data.length) {
    //     return <div>No assets for this category or project</div>;
    // }

    const handleRowClick = async (e, row) => {
        // clear search options
        setAssetFetchOptions(`?includeCustomAttributes=true&filter[categoryId]=${selectedCategoryId}&limit=200`);

        // Remove the 'clicked' class from the previously clicked row
        if (prevRowRef.current) {
            prevRowRef.current.classList.remove('clicked');
        }

        // Add the 'clicked' class to the currently clicked row
        const currentRow = row.getElement();
        currentRow.classList.add('clicked');

        // Store the currently clicked row element in the ref
        prevRowRef.current = currentRow;
        // set the active for viewer and relations
        const rowData = row.getData();
        console.log("Row clicked Tag Id:", rowData);
        setSelectedAssetId(rowData.id);
        //update input field
        setSelectedTagId(rowData.clientAssetId);
        // to zoom in with viewer
        setSelectedTagIdViewerLeft(rowData.clientAssetId);
        setSelectedTagIdViewerRight(rowData.clientAssetId);



        // Iterate through each cell in the row and apply the appropriate class to mark cells with matching custom properties

// Fetch custom attributes for the row's category
        const categoryName = rowData.categoryId;
        const categoryId = Object.keys(categoryOptions).find(key => categoryOptions[key] === categoryName);

        if (categoryId) {
            try {
                const accessToken = await getAccessToken();
                const customAttributesResponse = await FetchCategoriesCustomAttributes({
                    selectedProjectId,
                    categoryId,
                    accessToken
                });

                setCustomAttributes(customAttributesResponse.results); // Store custom attributes in state
                // console.log('Custom Attributes:', customAttributesResponse.results);

                // Iterate through each cell in the row and apply the appropriate class
                row.getCells().forEach(cell => {
                    const headerName = cell.getColumn().getField();
                    if (headerName in customPropsMap) {
                        const displayName = customPropsMap[headerName];
                        const isMatching = customAttributesResponse.results.some(attr => attr.displayName === displayName);
                        const cellElement = cell.getElement();

                        if  (!isMatching) {
                            cellElement.classList.add('non-matching-custom-prop');
                            cellElement.classList.remove('matching-custom-prop');
                        }
                    }
                });

            } catch (error) {
                console.error('Error fetching custom attributes:', error);
                setCustomAttributes([]); // Ensure customAttributes is an array in case of error
            }
        } else {
            console.error('Category ID not found for category name:', categoryName);
        }
    };




    const options = {
        layout: 'fitColumns',
        tooltips: true,
        addRowPos: 'top',
        history: false,
        // pagination: 'local',
        paginationSize: 200,
        pagination: false, //enable pagination
        // paginationMode:"remote", //enable remote pagination
        // ajaxURL:"http://testdata.com/data", //set url for ajax request
        // ajaxParams:{token:"ABC123"}, //set any standard parameters to pass with the request
        movableColumns: false,
        resizableRows: false,
        // // TODO using header filter makes table flickering if using this sort
        // initialSort: [
        //     {column: 'clientAssetId', dir: 'asc'}
        // ],
        rowContextMenu: rowMenu,
        // ajaxLoader: false // Disable the Ajax loader
    };



    const handleInputChange = (event) => {
        setSelectedTagId(event.target.value);
        setAssetFetchOptions(`?filter[searchText]=${selectedTagId}&includeCustomAttributes=true&includeDeleted=false&limit=200`)

        // console.log('Selected Tag ID handleInputChange:', selectedTagId);
    };
    // Handle button click
    const handleClearInput = () => {
        setSelectedTagId('');
        setAssetFetchOptions(`?includeCustomAttributes=true&filter[categoryId]=${selectedCategoryId}&limit=200`);

    };

    const handleReloadClick = () => {
        setReloadFlag(prevFlag => !prevFlag);
    };

    const toggleRow = async (row) => {
        const isExpanded = row.getElement().classList.contains('expanded');
        row.getElement().classList.toggle('expanded', !isExpanded);
        row.getElement().classList.toggle('collapsed', isExpanded);

        const nextRow = row.getNextRow();
        if (isExpanded) {
            if (nextRow && nextRow.getElement().classList.contains('subtable')) {
                nextRow.delete();
            }
        } else {
            const clientAssetId = row.getData().clientAssetId; // Assuming the correct field name
            try {
                const data = await AirtableService('Client_Asset_ID', clientAssetId);
                const headers = Object.keys(data);
                const details = Object.values(data);

                const newRowData = {
                    id: `detail-${row.getData().id}`,
                    subtable: true,
                    Client_Asset_ID: '',  // Placeholder
                    gender: '',  // Placeholder
                    age: '',  // Placeholder
                    content: `
                    <div>
                        <table  style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                            <thead>
                                <tr style="background: #f2f2f2;">
                                    ${headers.map(header => `<th style="border: 1px solid #ddd; padding: 8px;">${header}</th>`).join('')}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    ${details.map(detail => `<td style="border: 1px solid #ddd; padding: 8px;">${detail}</td>`).join('')}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                `,
                };

                // Adding the new row below the current row
                const newRow = await row.getTable().addRow(newRowData, false, row, true);
                if (newRow) {
                    const newRowElement = newRow.getElement();
                    newRowElement.classList.add('subtable');
                    newRowElement.querySelector('.tabulator-cell').innerHTML = newRowData.content;

                    // Remove any inherited class from the new row
                    // newRowElement.classList.remove('expanded'); // Example of removing 'expanded' class
                    newRowElement.classList.remove('tabulator-row'); // Example of removing 'collapsed' class
                } else {
                    console.error('New row was not found after addition.');
                }
            } catch (error) {
                console.error('Error fetching row data:', error);
            }
        }
    };
    const assetModes = [
        { label: 'Select options', value: '' },
        { label: 'Link to EB data', value: 'link2AirTable' }
    ];
    const handleSelectOptions = (event) => {
        const value = event.target.value;

        setSelectedOptions((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((mode) => mode !== value)
                : [...prevSelected, value]
        );

        setActions((prevActions) => ({
            ...prevActions,
            [value]: !prevActions[value]
        }));
    };


    return (
        <div>
            <button onClick={handleReloadClick} className="btn btn-secondary">
                Get assets
            </button>
            {/*<button onClick={handleReloadClick} className="btn btn-secondary">*/}
            {/*    Show in left viewer*/}
            {/*</button>*/}
            <div>
                <div className="d-flex align-items-center">
                    <input
                        type="text"
                        placeholder="Enter Tag ID or any text"
                        value={selectedTagId}
                        onChange={handleInputChange}
                        className="form-control"
                        style={{width: '200px', marginRight: '10px'}} // Set specific width and margin
                    />
                    <button onClick={handleClearInput} className="btn btn-secondary">
                        clear
                    </button>
                </div>
                {/*{selectedTagId && <p>Selected Tag ID: {selectedTagId}</p>}*/}
            </div>
            {showSaveButton && (
                <div>
                    <button className="btn btn-secondary" onClick={handleSaveClick}>Save Asset changes</button>
                </div>
            )}
            {assetUpdated && (
                <div>Asset is updated</div>
            )}
            <CSVLink data={data} headers={columns.map(col => ({label: col.title, key: col.field}))} filename="data.csv"
                     className="btn btn-secondary">
                Download CSV
            </CSVLink>

            <select
                id="assetModeDropdown"
                // className="form-select"
                value=""
                onChange={handleSelectOptions}
                style={{height: 'auto'}}
            >
                {assetModes.map((mode) => (
                    <option
                        key={mode.value}
                        value={mode.value}
                        style={{
                            backgroundColor: selectedOptions.includes(mode.value) ? 'green' : 'white',
                            color: selectedOptions.includes(mode.value) ? 'white' : 'black'
                        }}
                    >
                        {mode.label}
                    </option>
                ))}
            </select>

            <ReactTabulator
                ref={tableRef}
                data={data}
                columns={columns}
                options={options}
                // tooltips={true}
                layout={'fitData'}
                events={{cellEdited: handleCellEdit, rowClick: handleRowClick}}
                key={editableRowIndex} // Force re-render by changing key

            />


        </div>
    );
};

export default TabulatorTable;
