// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGlobalProvider } from "./GlobalProvider";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const { setLoginStatus } = useGlobalProvider(); // Assuming useGlobalProvider is defined

    const getAccessToken = async () => {
        try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/token`, {
                credentials: 'include',
            });
            if (!tokenResponse.ok) throw new Error(await tokenResponse.text());

            const { access_token, expires_in } = await tokenResponse.json();

            console.log('Access token obtained in AuthContext:', access_token, expires_in);
            return access_token; // Return the token
        } catch (err) {
            console.error('Error obtaining access token:', err.message);
            // alert('Session expired. Redirecting to login.');
            handleLogout(); // Ensure any stale tokens are cleared.
            handleLogin(); // Redirect user to login.
        }
    };

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                console.log('Fetching user profile from...', process.env.REACT_APP_API_URL);
                const profileResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/profile`, {
                    credentials: 'include',
                });

                // Log the response status and headers
                console.log('Response status:', profileResponse.status);
                console.log('Response headers:', profileResponse.headers);

                // Clone the response to log the body while still allowing it to be used later
                const responseClone = profileResponse.clone();
                const responseBody = await responseClone.text();
                console.log('Response body:', responseBody);

                if (profileResponse.ok) {
                    const userData = await profileResponse.json();
                    setUser(userData);
                    await getAccessToken();
                    setLoginStatus(true);
                } else {
                    console.error('Error: Non-OK HTTP status', profileResponse.status);
                    setUser(null);
                }
            } catch (err) {
                console.error('Error fetching user profile:', err);
                setError('Could not initialize the application. See console for more details.');
            }
        };

        fetchProfile();
    }, [setLoginStatus]);


    const handleLogout = () => {
        console.log('Logging out...');
        const iframe = document.createElement('iframe');
        iframe.style.visibility = 'hidden';
        iframe.src = 'https://accounts.autodesk.com/Authentication/LogOut';
        document.body.appendChild(iframe);
        iframe.onload = () => {
            window.location.replace(`${process.env.REACT_APP_API_URL}/api/auth/logout`);
            document.body.removeChild(iframe);
        };

    };

    const handleLogin = () => {
        console.log(`Login url:${process.env.REACT_APP_API_URL}/api/auth/login`)
        window.location.replace(`${process.env.REACT_APP_API_URL}/api/auth/login`);
    };

    return (
        <AuthContext.Provider value={{ user, error, handleLogout, handleLogin, getAccessToken }}>
            {children}
        </AuthContext.Provider>
    );
};
