// Description: This file contains the API service for fetching assets from the Autodesk Construction Cloud API.
// It will fetch all assets by following the `nextUrl` in the response.
export const FetchAssets = async ({ selectedProjectId, selectedCategoryId, accessToken }, assetFetchOptions = '') => {
    // selectedCategoryId = selectedCategoryId == null ? "1" : selectedCategoryId;

    const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });

    // Ensure assetFetchOptions starts with "?"
    if (!assetFetchOptions.startsWith('?')) {
        assetFetchOptions = '?' + assetFetchOptions;
    }

    // Function to fetch a single page of data and handle pagination recursively
    const fetchPaginatedData = async (url, accumulatedAssets = []) => {
        console.log('Fetching URL:', url); // Log the request URL

        const request = new Request(url, {
            method: 'GET',
            headers: requestHeaders
        });

        try {
            const response = await fetch(request);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            console.log('Fetched data:', data); // Log the response data

            // Accumulate assets from the current response
            const allAssets = accumulatedAssets.concat(data.results || []);

            // If there's a `nextUrl`, recursively fetch the next page
            if (data.pagination && data.pagination.nextUrl) {
                console.log('Next URL found:', data.pagination.nextUrl);
                return await fetchPaginatedData(data.pagination.nextUrl, allAssets);
            } else {
                // If there's no `nextUrl`, return all accumulated assets
                console.log('No more pages. Returning accumulated assets:', allAssets.length);
                // console.log('All Assets:', allAssets);
                return {
                    results: allAssets
                };

            }
        } catch (error) {
            console.error('Error fetching assets:', error);
            throw error;
        }
    };

    // Construct the initial URL for the first API request
    const initialUrl = `https://developer.api.autodesk.com/construction/assets/v2/projects/${selectedProjectId}/assets${assetFetchOptions}`;
    console.log('Initial URL:', initialUrl);

    // Start fetching from the initial URL and return all accumulated assets
    return await fetchPaginatedData(initialUrl);
};
